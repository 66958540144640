<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                <template v-if="!displayForm('showAddSingleStockWriteOffItemForm')">
                    Pozīcijas
                </template>
                <template v-else>
                    Pievienot papildus pozīciju
                </template>
            </h3>
        </template>

        <template v-slot:buttons>
            <template v-if="!displayForm('showAddSingleStockWriteOffItemForm')">
                <Button icon="plus" @click="showForm('showAddSingleStockWriteOffItemForm')" />
            </template>

            <template v-if="displayForm('showAddSingleStockWriteOffItemForm')">
                <Button icon="close" @click="hideAddForm" />
            </template>
        </template>

        <template v-slot:content>
        
            <template v-if="displayForm('showAddSingleStockWriteOffItemForm')">
                <AddStockWriteOffItem :warehouse="warehouse" :technicalDocument="technicalDocument" />
            </template>

            <!--  List all order items  -->
            <template v-if="items && !displayForm('showAddSingleStockWriteOffItemForm')" >
                <div class="divide-y divide-gray-200 dark:divide-gray-700 w-full">
                    <SingleStockWriteOffItemDetails v-for="item in items" :key="item.id" :item="item" :warehouse="warehouse" :technicalDocument="technicalDocument" />
                </div>
            </template>
                
        </template>
    </item-card>
</template>

<script>
import {mapGetters} from 'vuex'
import { defineAsyncComponent } from 'vue'
import ItemCard from "@/components/Components/ItemCard"

const SingleStockWriteOffItemDetails = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/WriteOff/SingleStockWriteOffItemDetails'))
const AddStockWriteOffItem = defineAsyncComponent(() =>
  import('@/components/Stock/TehnicalDocuments/WriteOff/AddStockWriteOffItem'))

export default {
    name: "StockWriteOffItem",
    components: {
        ItemCard,
        SingleStockWriteOffItemDetails,
        AddStockWriteOffItem,
    },
    props: {
        items: {
            required: true
        },
        warehouse: {
            required: true
        },
        technicalDocument: {
            required: true
        },
    },
    computed:{
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        })
    },
    methods: {
        showForm(formName){
          this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
          return this.formsForDisplay.includes(formName)
        },
        hideAddForm(){
            this.$store.dispatch('removeAllFormsForDisplay')
        },
    }
}
</script>

<style>

</style>